import React from 'react';
import { graphql } from 'gatsby';
import { Grid, Typography } from '@smooth-ui/core-sc';

import Layout from 'components/Layout';
import BlockContent from 'components/block-content';
import SEO from 'components/seo';

export const query = graphql`
  query PrivacyPageQuery {
    sanityPrivacy {
      _id
      _rawBody
      seo {
        title
        description
        image {
          asset {
            fixed(toFormat: JPG, width: 1200, height: 630) {
              src
            }
          }
        }
      }
    }
  }
`;

const Privacy = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={data.sanityPrivacy.seo.title}
        description={data.sanityPrivacy.seo.description}
        image={data.sanityPrivacy.seo.image.asset.fixed.src}
      />
      <Grid>
        <Typography variant="h1" my={3}>
          Privacy verklaring
        </Typography>
        <BlockContent blocks={data.sanityPrivacy._rawBody || []} />
      </Grid>
    </Layout>
  );
};

export default Privacy;
